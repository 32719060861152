import React from "react";
import ServiceCardImg from "../../assets/images/service-img.jpg";
import { primaryColor, secondaryColor } from "../../constants/theme";
import { NavLink } from "react-router-dom";

const ServiceCard = ({service, img}) => {
  console.log(service)
  return (
    <div className="col-md-6 col-lg-4 col-sm-12 col-12 d-flex justify-content-center align-items-center">
      <div className="card  mt-4">
        <img
          className="card-img-top"
          src={img}
          alt="Card image cap"
        />
        <div
          style={{ backgroundColor: primaryColor }}
          className="p-3 text-white d-flex justify-content-between px-3 align-items-center"
        >
          <div>
            <h6
              className="pt-1"
              style={{
                fontFamily: "Rajdhani",
                fontWeight: "700",
                fontSize: 24,
              }}
            >
              {service}
            </h6>
          </div>
          <NavLink
            style={{
              backgroundColor: secondaryColor,
              width: 30,
              height: 30,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              color:'#fff '
            }}
            to="/services"
          >
            <i class="fa fa-plus" aria-hidden="true"></i>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
