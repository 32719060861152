import React from "react";
import FeaturedProjects from "../../components/Home/featured-projects-section";
import CommonHeroSection from "../../components/commonHero";
import styles from "../About/styles.module.css";
import { primaryColor, secondaryColor } from "../../constants/theme";
import Footer from "../../components/Footer/footer";

const Project = () => {
  return (
    <div>
      <CommonHeroSection heading="Our Projects" />
      <div className="mt-5">
      <FeaturedProjects />
      </div>
      <div className="container mt-5 mb-5">
        <h1
          style={{ color: secondaryColor }}
          className={`${styles.projectsOtherHeading}`}
        >
          Other Projects
        </h1>
        <div className="row mt-4">
          <div className="col-md-6 col-lg-6 col-sm-12 col-12 mt-4">
            <div
              style={{ borderColor: primaryColor }}
              className={`${styles.projectCard}`}
            >
              <h5
                className={`${styles.projectHeadings}`}
                style={{ color: secondaryColor, fontWeight: "bolder" }}
              >
                Military Facilities
              </h5>
              <div className="mt-5 d-flex align-items-center ">
                <p
                  className={`${styles.projectText} ml-4`}
                  style={{ color: primaryColor }}
                >
                  • 706 Army Base Workshop, Kahuta <br /> • PAF Academy,
                  Risalpur <br /> • Pakistan Navy Engineering Complex, Karachi{" "}
                  <br /> • Fauji Foundation Complex, Ghotki <br /> • US Coast
                  Guard Outposts, Karachi to Makran Coast <br /> • Coalition
                  Forces Officers Barracks, Peshawar <br /> • Army Exchange
                  building, Peshawar
                </p>
              </div>
            </div>
          </div>
          {/* ======================================================================= */}
          <div className="col-md-6 col-lg-6 col-sm-12 col-12 mt-4">
            <div
              style={{ borderColor: primaryColor }}
              className={`${styles.projectCard}`}
            >
              <h5
                className={`${styles.projectHeadings}`}
                style={{ color: secondaryColor, fontWeight: "bolder" }}
              >
                Government Facilities
              </h5>
              <div className="mt-5 d-flex align-items-center ">
                <p
                  className={`${styles.projectText} ml-4`}
                  style={{ color: primaryColor }}
                >
                  • SUPARCO Complex, Karachi <br /> • Sindh High Court Annex,
                  Karachi <br /> • Federal Public Service Commission Building,
                  Islamabad <br /> • KPK Civil Secretariat, Peshawar <br /> •
                  KPK Chief Minister’s Residence, Peshawar <br /> • National
                  Police Foundation Building, Islamabad <br /> • State Bank of
                  Pakistan, Lahore
                </p>
              </div>
            </div>
          </div>
          {/* ======================================================================= */}
          <div className="col-md-6 col-lg-6 col-sm-12 col-12 mt-4">
            <div
              style={{ borderColor: primaryColor }}
              className={`${styles.projectCard}`}
            >
              <h5
                className={`${styles.projectHeadings}`}
                style={{ color: secondaryColor, fontWeight: "bolder" }}
              >
                Educational Institutions
              </h5>
              <div className="mt-5 d-flex align-items-center ">
                <p
                  className={`${styles.projectText} ml-4`}
                  style={{ color: primaryColor }}
                >
                  • Pakistan Atomic Energy Commision School of Excellence,
                  Islambad <br /> • Islamic Research Centers, Karachi, Lahore
                  Islamabad <br />
                  • Council of Islamic Ideology, Islamabad <br /> • Agriculture
                  University, Peshawar <br /> • DHA Model School Phase VII,
                  Karachi <br /> • DHA Women’s College, Karachi <br /> •
                  Pakistan Institute of Hotel Management, Karachi <br /> • FFC
                  School, Mirpur Mathelo <br /> • PNR School, Islamabad
                </p>
              </div>
            </div>
          </div>
          {/* ======================================================================= */}
          <div className="col-md-6 col-lg-6 col-sm-12 col-12 mt-4">
            <div
              style={{ borderColor: primaryColor }}
              className={`${styles.projectCard}`}
            >
              <h5
                className={`${styles.projectHeadings}`}
                style={{ color: secondaryColor, fontWeight: "bolder" }}
              >
                Airports & Aviation Related Structures
              </h5>
              <div className="mt-5 d-flex align-items-center ">
                <p
                  className={`${styles.projectText} ml-4`}
                  style={{ color: primaryColor }}
                >
                  • Karachi International Airport, Jinnah Terminal <br /> •
                  Allama Iqbal International Airport, Lahore <br /> • Extension
                  of Terminal 2, Karachi Airport <br /> • PIA Hanger Gate # 3,
                  Karachi Airport <br /> • ACC Building, Civil Aviation
                  Authority, Karachi Airport <br /> • PIA Precision Engineering
                  Workshops, Karachi Airport <br /> • PIA Simulator Building,
                  Karachi Airport <br /> • PIA Aircraft Engine Test Cell,
                  Karachi Airport <br /> • CAA Grid Station, Karachi Airport
                </p>
              </div>
            </div>
          </div>
          {/* ======================================================================= */}
          <div className="col-md-6 col-lg-6 col-sm-12 col-12 mt-4">
            <div
              style={{ borderColor: primaryColor }}
              className={`${styles.projectCard}`}
            >
              <h5
                className={`${styles.projectHeadings}`}
                style={{ color: secondaryColor, fontWeight: "bolder" }}
              >
                Sports, Recreational & Cultural Facilities
              </h5>
              <div className="mt-5 d-flex align-items-center ">
                <p
                  className={`${styles.projectText} ml-4`}
                  style={{ color: primaryColor }}
                >
                  • Karachi Sports Complex, Karachi <br /> • Pakistan National
                  Arts Gallery, Islamabad & Karachi <br /> • Quaid-e-Azam
                  Museum, Islamabad <br />
                  • Archeology Museum, Peshawar <br /> • Karachi Golf Club,
                  Karachi <br /> • National Sports Complex, Lahore <br /> •
                  National Sports Training and Coaching Center, Quetta <br /> •
                  Islamabad Club Swimming Pool Complex, Islamabad <br /> • Club
                  House for Pakistan Cricket Board, Karachi <br /> • Officers
                  Club, Pakistan Steel, Karachi <br /> • Civil Aviation Club,
                  Karachi <br /> • Swimming Pool at FFC, Ghotki <br /> • Mardan
                  Stadium, Mardan
                </p>
              </div>
            </div>
          </div>
          {/* ======================================================================= */}
          <div className="col-md-6 col-lg-6 col-sm-12 col-12 mt-4">
            <div
              style={{ borderColor: primaryColor }}
              className={`${styles.projectCard}`}
            >
              <h5
                className={`${styles.projectHeadings}`}
                style={{ color: secondaryColor, fontWeight: "bolder" }}
              >
                International Projects
              </h5>
              <div className="mt-5 d-flex align-items-center ">
                <p
                  className={`${styles.projectText} ml-4`}
                  style={{ color: primaryColor }}
                >
                  • Kuala Lumpur International Airport <br /> • Beverly Towers,
                  Kuala Lumpur <br /> • Telekom Malaysia Building, Kuala Lumpur{" "}
                  <br /> • Epstein Redevelopment, USA <br /> • Crane Mill Care
                  Building, USA <br /> • Clinton Processing Company, USA <br />{" "}
                  • Marine Police H.Q, Kuala Lumpur <br /> • Nova Nordisk Office
                  Building, Kuala Lumpur <br /> • Al Rajhi Mosque, Riyadh <br />{" "}
                  • Ned Lloyd House Redevelopment, Singapore <br /> • Grange
                  Road Medical Center, Singapore <br /> • Anglo-Chinese School,
                  Singapore <br /> • SMART School, Kuala Lumpur <br /> • P.K.K.M
                  Office & Amphitheatre, Kuala Lumpur <br /> • Marina Resort
                  Apts. , Kuala Lumpur <br /> • Rashid Hussain Berhad Commercial
                  Complex, Kuala Lumpur <br /> • Villa at Emirates Hill, Dubai
                </p>
              </div>
            </div>
          </div>
          {/* ======================================================================= */}
          <div className="col-md-6 col-lg-6 col-sm-12 col-12 mt-4">
            <div
              style={{ borderColor: primaryColor }}
              className={`${styles.projectCard}`}
            >
              <h5
                className={`${styles.projectHeadings}`}
                style={{ color: secondaryColor, fontWeight: "bolder" }}
              >
                Industries
              </h5>
              <div className="mt-5 d-flex align-items-center ">
                <p
                  className={`${styles.projectText} ml-4`}
                  style={{ color: primaryColor }}
                >
                  • Pepsi Cola, Karachi <br /> • International Textile Mills,
                  Karachi <br /> • Gul Ahmed Textile Mills, Karachi <br /> •
                  Globe Textile Mills, Karachi <br /> • MAK Textile, Karachi{" "}
                  <br /> • Latif Textile Mills, Karachi
                  <br /> • Nakshbandi Industries, Karachi <br /> • Lakson
                  Tobacco, Various Sites <br /> • Pakistan Tobacco, Various
                  Sites <br /> • Habib Oil Mills, Karachi <br /> • DYNO
                  Pakistan, Hub <br /> • ACME Mills, Karachi <br /> • Mehran
                  Jute Mills, Karachi <br /> • Seagull Industries, Karachi{" "}
                  <br /> • Eastern Export, Karachi
                </p>
              </div>
            </div>
          </div>
          {/* ======================================================================= */}
          <div className="col-md-6 col-lg-6 col-sm-12 col-12 mt-4">
            <div
              style={{ borderColor: primaryColor }}
              className={`${styles.projectCard}`}
            >
              <h5
                className={`${styles.projectHeadings}`}
                style={{ color: secondaryColor, fontWeight: "bolder" }}
              >
                Office & Commercial Buildings
              </h5>
              <div className="mt-5 d-flex align-items-center ">
                <p
                  className={`${styles.projectText} ml-4`}
                  style={{ color: primaryColor }}
                >
                  • Grand Hyatt, Islamabad <br /> • Centaurus Mixed Used
                  Development Complex, Islamabad <br /> • Corniche Tower,
                  Karachi <br /> • State Life Tower, Islamabad <br /> • The Mall
                  99, Lahore <br /> • Center Point Tower, Karachi <br /> •
                  Carrefour Shopping Center, Lahore <br /> • Lakson Square
                  Complex, Karachi <br /> • Lever Brothers Office Building,
                  Karachi <br /> • Gul Ahmed City Office, Lahore <br /> •
                  SWISSTEX Chemical Office Building, Karachi <br /> • PIA Sales
                  Office, Various Sites <br /> • PIA Shopping Center, Karachi{" "}
                  <br /> • State Life Building, Gujranwala
                </p>
              </div>
            </div>
          </div>
          {/* ======================================================================= */}
          <div className="col-md-6 col-lg-6 col-sm-12 col-12 mt-4">
            <div
              style={{ borderColor: primaryColor }}
              className={`${styles.projectCard}`}
            >
              <h5
                className={`${styles.projectHeadings}`}
                style={{ color: secondaryColor, fontWeight: "bolder" }}
              >
                Roads and Bridges
              </h5>
              <div className="mt-5 d-flex align-items-center ">
                <p
                  className={`${styles.projectText} ml-4`}
                  style={{ color: primaryColor }}
                >
                  • A.D.B. Assisted Farm to Market Roads, Phase I, Punjab <br />{" "}
                  • SITE Area Road Rehabilitation, Karachi <br /> • Bridge Over
                  Pat Nadi, Baluchistan <br /> • Bridge Over Karolak Nadi,
                  Baluchistan <br /> • Bridge Over Pat II Nulah, Baluchistan{" "}
                  <br /> • Bridge Over Bakhalo Nullah, Baluchistan <br /> •
                  Bridge at Angoori Heights, Islamabad <br /> • Urnikas Bridge,
                  Azad Kashmir
                </p>
              </div>
            </div>
          </div>
          {/* ======================================================================= */}
          <div className="col-md-6 col-lg-6 col-sm-12 col-12 mt-4">
            <div
              style={{ borderColor: primaryColor }}
              className={`${styles.projectCard}`}
            >
              <h5
                className={`${styles.projectHeadings}`}
                style={{ color: secondaryColor, fontWeight: "bolder" }}
              >
                Hotels and Restaurants
              </h5>
              <div className="mt-5 d-flex align-items-center ">
                <p
                  className={`${styles.projectText} ml-4`}
                  style={{ color: primaryColor }}
                >
                  • Pearl Continental Hotel, Abbottabad <br /> • PTDC Motels,
                  Various Locations Throughout Pakistan <br /> • Dreamworld
                  Family Resort, Karachi <br /> • Pizza Hut, Karachi and
                  Hyderabad <br /> • McDonalds, Jinnah Park, Rawalpindi and F-9
                  Islamabad
                </p>
              </div>
            </div>
          </div>
          {/* ======================================================================= */}
          <div className="col-md-6 col-lg-6 col-sm-12 col-12 mt-4">
            <div
              style={{ borderColor: primaryColor }}
              className={`${styles.projectCard}`}
            >
              <h5
                className={`${styles.projectHeadings}`}
                style={{ color: secondaryColor, fontWeight: "bolder" }}
              >
                Residences
              </h5>
              <div className="mt-5 d-flex align-items-center ">
                <p
                  className={`${styles.projectText} ml-4`}
                  style={{ color: primaryColor }}
                >
                  • Residence of Mr. S. Poonegar, Quetta <br /> • Residence of
                  Mr. Siraj Teli, Karachi <br /> • Residence of Mr. Sultan
                  Lakhani, Karachi
                  <br /> • Residence of Mr. Nasir Jaffer, Karachi <br /> •
                  Residence of the Tabani Family, Karachi
                </p>
              </div>
            </div>
          </div>
          {/* ======================================================================= */}
          <div className="col-md-6 col-lg-6 col-sm-12 col-12 mt-4">
            <div
              style={{ borderColor: primaryColor }}
              className={`${styles.projectCard}`}
            >
              <h5
                className={`${styles.projectHeadings}`}
                style={{ color: secondaryColor, fontWeight: "bolder" }}
              >
                Others
              </h5>
              <div className="mt-5 d-flex align-items-center ">
                <p
                  className={`${styles.projectText} ml-4`}
                  style={{ color: primaryColor }}
                >
                  • US Aid Facility, Islamabad <br /> • Agility Logistics
                  Warehouse, Karachi <br /> • Caltex Warehouse, Rawalpindi{" "}
                  <br /> • Silver Oaks Apartments, Islamabad <br /> • Embassy of
                  the Netherlands, Islamabad <br />• Embassy of Poland,
                  Islamabad
                </p>
              </div>
            </div>
          </div>
          {/* ======================================================================= */}
        </div>
      </div>
      <div style={{marginTop:90}}>
      <Footer />
      </div>
    </div>
  );
};

export default Project;
