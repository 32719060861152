import React from "react";
import styles from "../../pages/Home/styles.module.css";
import { primaryColor, secondaryColor } from "../../constants/theme";
import ServiceCard from "./service-card";
import {servicesData} from '../../data/services-data'

const FullStackServices = () => {
  return (
    <div  className="container-fluid my-4">
      <div className="d-flex justify-content-center align-items-lg-center flex-column">
        <div className={`${styles.mainHeadingSec}`}>
          <h6
            style={{ color: secondaryColor }}
            className={`${styles.servicesHeading}`}
          >
            OUR SERVICES
          </h6>
          <h1
            style={{ color: primaryColor }}
            className={`${styles.fullStackHeading} h1-responsive `}
          >
            Full Stack Services
          </h1>
          
      
        </div>
        <p style={{color: primaryColor}} className={`${styles.servicesSubTxt} mx-1`}>
            Covering all aspect of Building & Construction Engineering –
            comprising the planning, design, budgeting, construction management,
            surveying and analysis.
          </p>
      </div>
      <div className="container">
     
        <div className="row">
          {
            servicesData.map((v,i) => {
              return (
                <ServiceCard service={v.service} img={v.img}  />
              )
            })
          }
        </div>
      </div>
    </div>
  );
};

export default FullStackServices;
