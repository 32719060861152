import React, { useState } from "react";
import Slider from "react-slick";
import BlueBg from "../../assets/images/Blue-bg.jpg";
import { secondaryColor } from "../../constants/theme";
import styles from "../../pages/Home/styles.module.css";
import Centaurus from "../../assets/images/Centaurus.jpg";
import GrandHyat from "../../assets/images/grand-hyat.jpg";
import KIA from "../../assets/images/KIA.jpg";
import KLIA from "../../assets/images/KLIA.jpg";
import MalaysiaTelecom from "../../assets/images/malaysia-telecom-building.jpg";
import NAG from "../../assets/images/NAG.jpg";
import PCPESHAWAR from "../../assets/images/pc-peshawar.jpg";
import PMI from "../../assets/images/PMI.jpg";
import SukkurIBA from "../../assets/images/sukkur-iba.jpg";
import Dreamworld from "../../assets/images/dream-world.jpg";

const FeaturedProjects = () => {
  const [dotIndex, setDotIndex] = useState();
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    dotsClass: "slick-dots",
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <>
        <i
          class="fa fa-angle-right fa-2x"
          onClick={onClick}
          style={{
            ...style,
            bottom: 50,
            right: 40,
            cursor: "pointer",
            zIndex: 1111,
            position: "absolute",
            // marginRight: 100,
            zIndex: 1111,
            position: "absolute",
            display: "block",
            background: "white",
            borderRadius: 100,
            padding: 15,
            width: 60,
            height: 60,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></i>
        ,
      </>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <>
        <i
          class="fa fa-angle-left fa-2x"
          onClick={onClick}
          style={{
            ...style,
            bottom: 50,
            right: 150,
            zIndex: 1111,
            cursor: "pointer",
            position: "absolute",
            display: "block",
            background: "white",
            borderRadius: 60,
            padding: 15,
            width: 60,
            height: 60,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></i>
        ,
      </>
    );
  }
  return (
    <div
      className={`container-fluid ${styles.mainDivFeatured}`}
      style={{
        marginTop: 180,
        marginBottom: 100,
        height: "auto",
        position: "relative",
      }}
    >
      <div className="row">
        <div
          className="col-md-12 col-lg-6 col-sm-12 col-12"
          style={{ padding: 0, width: "100%", overflow: "hidden" }}
        >
          <Slider afterChange={(e) => setDotIndex(e)} {...settings}>
            <div>
              <img
                src={KLIA}
                style={{ height: 550, width: "100%", objectFit: "cover" }}
                alt=""
              />
            </div>
            <div>
              <img
                src={MalaysiaTelecom}
                style={{ height: 550, width: "100%", objectFit: "cover" }}
                alt=""
              />
            </div>
            <div>
              <img
                src={KIA}
                style={{ height: 550, width: "100%", objectFit: "cover" }}
                alt=""
              />
            </div>
            <div>
              <img
                src={PCPESHAWAR}
                style={{ height: 550, width: "100%", objectFit: "cover" }}
                alt=""
              />
            </div>
            <div>
              <img
                src={Centaurus}
                style={{ height: 550, width: "100%", objectFit: "cover" }}
                alt=""
              />
            </div>
            <div>
              <img
                src={PMI}
                style={{ height: 550, width: "100%", objectFit: "cover" }}
                alt=""
              />
            </div>
            <div>
              <img
                src={GrandHyat}
                style={{ height: 550, width: "100%", objectFit: "cover" }}
                alt=""
              />
            </div>
            <div>
              <img
                src={NAG}
                style={{ height: 550, width: "100%", objectFit: "cover" }}
                alt=""
              />
            </div>
            <div>
              <img
                src={SukkurIBA}
                style={{ height: 550, width: "100%", objectFit: "cover" }}
                alt=""
              />
            </div>
            <div>
              <img
                src={Dreamworld}
                style={{ height: 550, width: "100%", objectFit: "cover" }}
                alt=""
              />
            </div>
          </Slider>
        </div>
        <div
          style={{
            backgroundImage: `url(${BlueBg})`,
            height: 550,
            width: "100%",
            marginTop: 24,
          }}
          className={`${styles.featuredProjectCard}  col-md-12 col-lg-6 col-sm-12 col-12  p-4 text-white`}
        >
          <div className="d-flex justify-content-center  mx-auto p-4 flex-column">
            <h6
              style={{ color: secondaryColor }}
              className={`${styles.servicesHeading}`}
            >
              OUR PROJECTS
            </h6>
            <h1
              style={{
                fontFamily: "Rajdhani",
                fontSize: 70,
                // textAlign: "center",
                fontWeight: "700",
              }}
              className={`${styles.featuredHeading}`}
            >
              Featured Projects
            </h1>
          </div>
          <h1
            style={{
              paddingTop: 140,
              fontFamily: "Rajdhani",
              fontSize: 30,
              textAlign: "center",
              fontWeight: "400",
            }}
            className={`${styles.buildingsName}`}
          >
            {dotIndex === 0
              ? "Kuala Lumpur International Airport, Kuala Lumpur | Structural Design Services"
              : dotIndex === 1
              ? "Malaysia Telekom Tower, Kuala Lumpur | Structural Design Services"
              : dotIndex === 2
              ? "Karachi International Airport, Karachi | Structural Design Services"
              : dotIndex === 3
              ? "Pearl Continental Hotel, Peshawar | Civil & MEP Design & Supervision"
              : dotIndex === 4
              ? "The Centaurus Mall, Islamabad | Structural Modifications & MEP Redesign"
              : dotIndex === 5
              ? "Philip Morris Intl. Factories, Sahiwal & Kotri | Civil & MEP Design and Supervision"
              : dotIndex === 6
              ? "Grand Hyatt, Islamabad | Vetting of Design"
              : dotIndex === 7
              ? "National Arts Gallery, Islamabad | Structural Design Services"
              : dotIndex === 8
              ? "Sukkur IBA, Sukkur | Structural Design Services"
              : dotIndex === 9
              ? "Dreamworld Resort, Karachi | Design & Supervision of Civil, Electrical & HVAC works"
              : "Kuala Lumpur International Airport, Kuala Lumpur | Structural Design Services"}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default FeaturedProjects;
