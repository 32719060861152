import React from "react";
import styles from "../../pages/Home/styles.module.css";
import { NavLink } from "react-router-dom";
import { primaryColor, secondaryColor } from "../../constants/theme";

const CommonCard = ({
  color,
  fontSize,
  textBlue,
  textOrange,
  para1,
  para2,
  para3,
  para4,
  para5,
  img,
  text,
  bgImg,
  heading,
}) => {
  return (
    <div className="col-md-6 col-lg-4 col-sm-12 col-12 my-2 d-flex justify-content-center align-items-center">
      <NavLink
        className={`${styles.card_container} card p-3`}
        style={{ backgroundColor: color, color: "#fff" }}
        to={{
          pathname: `/services/${textBlue}`,
          state: {
            color,
            fontSize,
            textBlue,
            textOrange,
            para1,
            para2,
            para3,
            para4,
            para5,
            img,
            text,
            bgImg,
            heading,
          },
        }}
      >
        <div
          className="card-body"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h5
            style={{ alignItems: "center", textAlign: "center" }}
            className={`${styles.card_heading}`}
          >
            {heading}
          </h5>
          <p style={{ textAlign: "center" }} className={`${styles.card_text}`}>
            {text}
          </p>
        </div>
        <img src={img} className={`${styles.card_img} img-fluid`} alt="" />
      </NavLink>
    </div>
  );
};

export default CommonCard;
