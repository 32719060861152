import React from "react";
import styles from "../../pages/Home/styles.module.css";
import { primaryColor, secondaryColor } from "../../constants/theme";
import Pattern from "../../assets/images/pattern.png";
import AboutSectionImg from "../../assets/images/About.jpg";
import CardAboutSecImg from "../../assets/images/card-aboutsec.png";

const AboutSection = (props) => {
  return (
    <div className={`container my-4 ${styles.abousus_main}`}>
      <div className="row" style={{ height: "100%" }}>
        <div
          className={`col-md-6 col-lg-6 col-sm-12 col-12 p-2 ${styles.left_section2_about}`}
          style={{ position: "relative" }}
        >
          <h5
            className={`${styles.aboutus_heading} pr-5`}
            style={{ color: secondaryColor }}
          >
            About us
          </h5>
          <h1
            className={`${styles.heading_aboutSec} h1-responsive pr-5`}
            style={{ color: primaryColor }}
          >
            We Construct <br /> your needs
          </h1>
          <div style={{ position: "relative", marginTop: 30 }}>
            <img src={Pattern} className={`${styles.patternImg}`} alt="" />
            <img
              src={AboutSectionImg}
              className={`${styles.aboutSecImg}`}
              alt=""
            />
            <div
              className={`card ${styles.sinceBox}`}
              style={{ backgroundColor: primaryColor }}
            >
              <div className="card-body text-white d-flex  align-items-center">
                <h5 className={`${styles.sinceTxt}`}>Since</h5>
                <h1 className={`${styles.yearTxt}`}>1976</h1>
                <img
                  src={CardAboutSecImg}
                  height="110"
                  width="110"
                  style={{
                    position: "absolute",
                    right: 0,
                    bottom: 0,
                    opacity: 0.4,
                  }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-6 col-lg-6 col-12 col-sm-12"
          style={{ position: "relative" }}
        >
          <h1
            className={`${styles.heading_aboutSec_media} h1-responsive `}
            style={{ color: primaryColor }}
          >
            We Construct your needs
          </h1>

          <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:20}}>
          <div
              className={`card ${styles.sinceBoxMedia}`}
              style={{ backgroundColor: primaryColor }}
            >
              <div className="card-body text-white d-flex  align-items-center">
                <h5 className={`${styles.sinceTxt}`}>Since</h5>
                <h1 className={`${styles.yearTxt}`}>1976</h1>
                <img
                  src={CardAboutSecImg}
                  height="110"
                  width="110"
                  style={{
                    position: "absolute",
                    right: 0,
                    bottom: 0,
                    opacity: 0.4,
                  }}
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="my-5">
            <p
              className={`${styles.about_paragraph}`}
              style={{ color: primaryColor, marginTop:-12 }}
            >
              Established in 1976, SMK Associates is the leading civil and
              structural engineering consultancy firm in the region. Equipped
              with valuable experience by working on both international and
              local projects, we provide full-stack civil and strucutural
              engineering services.
            </p>
          </div>
          <div className="mt-4">
            <h4
              style={{
                fontFamily: "Rajdhani",
                fontSize: 29,
                color: primaryColor,
                fontWeight: "600",
              }}
            >
              We Provide best Engineering services for our clients with their
              satisfication
            </h4>
            <div className="mt-4">
              <p
                style={{
                  fontFamily: "Rajdhani",
                  fontSize: 17,
                  fontWeight: "500",
                  color: primaryColor,
                }}
              >
                We help to plan, deliver, and manage the infrastructure needed
                for a livable and prosperous future by collaborating with
                clients and collaborators to leverage the expertise of our
                diverse teams, as well as the power of data and technology.
                <br />
                Our services include:
              </p>
              {
                props.homepage === 'home' ? (
                  <ol
                style={{
                  fontFamily: "Rajdhani",
                  fontSize: 17,
                  fontWeight: "500",
                  color: primaryColor,
                  backgroundColor: "transparent",
                }}
                className={`${styles.servicesList}`}
              >
                <li>Architecture</li>
                <li>Building Design</li>
                <li>Construction Management</li>
                <li>Advisory Services</li>
                <li>Electrical Services</li>
                <li>Mechanical Engineering</li>
                <li>Fire Engineering</li>
                <li>Soil Testing</li>
                <li>Lift Installation Services</li>
              </ol>
                ) : (
                  <ol
                style={{
                  fontFamily: "Rajdhani",
                  fontSize: 17,
                  fontWeight: "500",
                  color: primaryColor,
                  backgroundColor: "transparent",
                }}
                className={`${styles.servicesList}`}
              >
                <li>Advisory services</li>
                <li>Structural Design</li>
                <li>Seismic and Dynamic Analysis</li>
                <li>Feasibility study and reports</li>
                <li>Green Building Design</li>
                <li>Industrial Architecture</li>
                <li>Full Project Management</li>
              </ol>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
