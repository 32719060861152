import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { primaryColor, secondaryColor } from "../../constants/theme";
import styles from "../About/styles.module.css";
import Footer from "../../components/Footer/footer";

const TeamMember = () => {
  const { name } = useParams();
  const { textBlue } = useParams();
  const { state } = useLocation();
  console.log(state);
  return (
    <div
      style={{
        backgroundImage: state.bgImg ? `url(${state.bgImg})` : null,
        // backgroundPosition: "center",
        // backgroundAttachment:'fixed',
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100%",
        height: "100%",
      }}
      className={`${styles.bgImgExtrapage}`}
    >
      <div className="container">
        <div className="row">
          <div
            className="col-md-6"
            style={{
              borderRightColor: "black",
              borderRightWidth: 1,
              borderRightStyle: "solid",
              marginTop: 189,
            }}
          >
            <h1
              style={{ color: primaryColor }}
              className={`text-center ${styles.memberName}`}
            >
              {name || textBlue}
            </h1>
          </div>
          <div
            style={{ marginTop: 180 }}
            className={`col-md-6  d-flex justify-content-center align-items-center ${styles.service_detail_col2}`}
          >
            <h1
              style={{ color: secondaryColor, fontSize: state.fontSize || 60 }}
              className={`text-center ${styles.memberPost}`}
            >
              {state.post || state.textOrange}
            </h1>
          </div>
        </div>
      </div>
      <div className="container mt-5 pb-5">
        <p className={`${styles.memberAbtTxt}`} style={{ color: primaryColor }}>
          {state.para1}
        </p>
        <p className={`${styles.memberAbtTxt}`} style={{ color: primaryColor }}>
          {state.para2}
        </p>
        <p className={`${styles.memberAbtTxt}`} style={{ color: primaryColor }}>
          {state.para3}
        </p>
        <p className={`${styles.memberAbtTxt}`} style={{ color: primaryColor }}>
          {state.para4}
        </p>
        <p className={`${styles.memberAbtTxt}`} style={{ color: primaryColor }}>
          {state.para5}
        </p>
        <p className={`${styles.memberAbtTxt}`} style={{ color: primaryColor }}>
          {state.para6}
        </p>
        {state.textBlue === "360° Consultancy" ? (
          <ul className={`${styles.listServ}`}>
            <li>
              <p
                className={`${styles.memberAbtTxt}`}
                style={{ color: primaryColor }}
              >
                Full design, design vetting and supervision services including
                production of contract documents, specifications and bill of
                quantities, working drawings and shop drawings.
              </p>
            </li>
            <li>
              <p
                className={`${styles.memberAbtTxt}`}
                style={{ color: primaryColor }}
              >
               Stability assessment of existing structures.
               </p>
            </li>
            <li>
              <p
                className={`${styles.memberAbtTxt}`}
                style={{ color: primaryColor }}
              >
                Blast resistant design services including designing structures
                to withstand blasts and various terrorist attacks.
              </p>
            </li>
            <li>
              <p
                className={`${styles.memberAbtTxt}`}
                style={{ color: primaryColor }}
              >
                Construction management services beginning with advice on the
                award of contracts and leading up to supervision of construction
                including verification of contractors’ bills.
              </p>
            </li>
            <li>
              <p
                className={`${styles.memberAbtTxt}`}
                style={{ color: primaryColor }}
              >
                Feasibility studies and cost estimates for both civil and
                structural engineering projects.
              </p>
            </li>
            <li>
              <p
                className={`${styles.memberAbtTxt}`}
                style={{ color: primaryColor }}
              >
                Reports on existing and proposed structures in relation to their
                stability or proposed modifications.
              </p>
            </li>
            <li>
              <p
                className={`${styles.memberAbtTxt}`}
                style={{ color: primaryColor }}
              >
                Seismic and dynamic analysis of all classes of structures.
              </p>
            </li>
            <li>
              <p
                className={`${styles.memberAbtTxt}`}
                style={{ color: primaryColor }}
              >
                Advice on mathematical modeling of structures.
              </p>
            </li>
            <li>
              <p
                className={`${styles.memberAbtTxt}`}
                style={{ color: primaryColor }}
              >
                Industrial architectural services.
              </p>
            </li>
            <li>
              <p
                className={`${styles.memberAbtTxt}`}
                style={{ color: primaryColor }}
              >
                Infrastructure (external works i.e. roads, pavement, landscape etc.)
              </p>
            </li>
            <li>
              <p
                className={`${styles.memberAbtTxt}`}
                style={{ color: primaryColor }}
              >
                Topographic Survey.
              </p>
            </li>
          </ul>
        ) : null}
      </div>
      <div
        style={{
          marginTop: state.textBlue === "360° Consultancy" ? -53 : null,
        }}
      >
        <Footer />
      </div>
    </div>
  );
};

export default TeamMember;
