import React from "react";
import { primaryColor } from "../../constants/theme";
import styles from "./styles.module.css";
import Logo from "../../assets/images/logo.png";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  return (
    <nav
      // style={{ backgroundColor: primaryColor }}
      className={`navbar ${styles.navbar_cont} fixed-top scrolling-navbar  navbar-expand-lg navbar-dark `}
    >
      <div className={` ${styles.logo}`} style={{ width: "15%" }}>
        <NavLink className={`navbar-brand  ${styles.logoBgwhite} `} to="/">
          <img
            src={Logo}
            style={{ objectFit: "contain" }}
            height="80"
            width="100"
            alt="mdb logo"
          />
        </NavLink>
      </div>

      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#basicExampleNav"
        aria-controls="basicExampleNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div
        className={`collapse navbar-collapse ${styles.collapseArea}`}
        style={{ width: "85%" }}
        id="basicExampleNav"
      >
        <ul className="navbar-nav mx-auto d-flex justify-content-center align-item-center">
          <li className="nav-item mx-4 ">
            <NavLink className="nav-link text-center" to="/">
              Home
            </NavLink>
          </li>
          <li className="nav-item mx-4">
            <NavLink className="nav-link text-center" to="/about">
              About us
            </NavLink>
          </li>
          <li className="nav-item mx-4">
            <NavLink className="nav-link text-center" to="/services">
              Services
            </NavLink>
          </li>
          <li className="nav-item mx-4">
            <NavLink className="nav-link text-center" to="/projects">
              Project
            </NavLink>
          </li>
          <li className="nav-item mx-4">
            <NavLink className="nav-link text-center" to="/clients">
              Clients
            </NavLink>
          </li>
          <li className="nav-item mx-4">
            <NavLink className="nav-link text-center" to="/contact">
              Contact
            </NavLink>
          </li>
        </ul>
        <ul
          className="navbar-nav ml-auto nav-flex-icons"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <li className="nav-item">
            <a
              target="_blank"
              href="https://twitter.com/SmkAssociates"
              className="nav-link waves-effect waves-light"
            >
              <i className="fab fa-twitter text-white  mx-2"></i>
            </a>
          </li>
          <li className="nav-item">
            <a
              target="_blank"
              href="https://www.facebook.com/SMK-Associates-102979235375444"
              className="nav-link waves-effect waves-light"
            >
              <i className="fab fa-facebook text-white  mx-2"></i>
            </a>
          </li>
          <li className="nav-item">
            <a
              target="_blank"
              href="https://www.linkedin.com/company/smkassociates"
              className="nav-link waves-effect waves-light"
            >
              <i className="fab fa-linkedin text-white  mx-2"></i>
            </a>
          </li>
          <li className="nav-item">
            <a
              target="_blank"
              href="https://www.instagram.com/smkassociates"
              className="nav-link waves-effect waves-light"
            >
              <i className="fab fa-instagram text-white  mx-2"></i>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
