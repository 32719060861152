import React from "react";
import styles from "../../pages/Home/styles.module.css";
import HeroBg from "../../assets/images/hero-bg.jpg";

const CommonHeroSection = ({heading}) => {
  return (
    <div>
      <div
        className={`d-flex justify-content-center align-items-center ${styles.cont_hero}`}
        style={{ backgroundImage: `url(${HeroBg})`,height:'55vh' }}
      >
        <div className="container my-4">
          <div className="row">
            <div
              className="col-md-9 white-text text-center text-md-left mt-xl-5 "
              data-wow-delay="0.3s"
            >
              <h1
                className={`h1-responsive font-weight-bold mt-sm-5 ${styles.hero_heading}`}
              >
               {heading}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonHeroSection;
