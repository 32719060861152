import React from "react";
import styles from "../../pages/Home/styles.module.css";
import Slider from "react-slick";
import Centaurus from "../../assets/images/logos/Centaurus.png";
import Dreamworld from "../../assets/images/logos/Dreamworld.png";
import CAA from "../../assets/images/logos/CAA.png";
import GulAhmed from "../../assets/images/logos/Gul-Ahmed.png";
import HOM from "../../assets/images/logos/HOM.png";
import Hyatt from "../../assets/images/logos/Hyatt.png";
import IBA from "../../assets/images/logos/IBA.png";
import Mcdonalds from "../../assets/images/logos/Mcdonalds.png";
import PC from "../../assets/images/logos/PC.png";
import PIA from "../../assets/images/logos/PIA.png";
import PMI from "../../assets/images/logos/PMI.png";
import PTC from "../../assets/images/logos/PTC.png";
import Suparco from "../../assets/images/logos/Suparco.png";
import { primaryColor, secondaryColor } from "../../constants/theme";

const Clients = () => {
  var settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 900,
    arrows: false,
    dots: false,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <div
      className="container-fluid"
      style={{ marginTop: 60, marginBottom: 30 }}
    >
      <div className="d-flex justify-content-center align-items-lg-center flex-column">
        <div className={`${styles.mainHeadingSec}`}>
          <h6
            style={{ color: secondaryColor }}
            className={`${styles.servicesHeading}`}
          >
            OUR CLIENTS
          </h6>
          <h1
            style={{ color: primaryColor }}
            className={`${styles.fullStackHeading} h1-responsive `}
          >
            Our Valuable Clients
          </h1>
        </div>

      <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
      <p
          style={{ color: primaryColor }}
          className={`${styles.servicesSubTxt} mx-3`}
        >
          We have worked closely and successfully with some of the biggest names
          in key public and private organizations, and pride ourselves on
          meeting and exceeding our clients’ expectations. Here, you can find a
          selection of the many client organizations we have served.
        </p>
      </div>
      </div>
      <Slider {...settings}>
        <div>
          <img
            src={Centaurus}
            alt=""
            className={`${styles.clientsLogo}`}
            style={{ objectFit: "contain", width: 280, height: 280 }}
          />
        </div>
        <div>
          <img
            src={PIA}
            alt=""
            style={{ objectFit: "contain", width: 280, height: 300 }}
            className={`${styles.clientsLogo}`}
          />
        </div>
        <div>
          <img
            src={PTC}
            alt=""
            style={{ objectFit: "contain", width: 280, height: 300 }}
            className={`${styles.clientsLogo}`}
          />
        </div>
        <div>
          <img
            src={Dreamworld}
            alt=""
            style={{ objectFit: "contain", width: 280, height: 300 }}
            className={`${styles.clientsLogo}`}
          />
        </div>
        <div>
          <img
            src={CAA}
            alt=""
            style={{ objectFit: "contain", width: 280, height: 300 }}
            className={`${styles.clientsLogo}`}
          />
        </div>
        <div>
          <img
            src={GulAhmed}
            alt=""
            style={{ objectFit: "contain", width: 280, height: 300 }}
            className={`${styles.clientsLogo}`}
          />
        </div>
        <div>
          <img
            src={PMI}
            alt=""
            style={{ objectFit: "contain", width: 280, height: 300 }}
            className={`${styles.clientsLogo}`}
          />
        </div>
        <div>
          <img
            src={Hyatt}
            alt=""
            style={{ objectFit: "contain", width: 280, height: 300 }}
            className={`${styles.clientsLogo}`}
          />
        </div>
        <div>
          <img
            src={Mcdonalds}
            alt=""
            style={{ objectFit: "contain", width: 280, height: 300 }}
            className={`${styles.clientsLogo}`}
          />
        </div>
        <div>
          <img
            src={HOM}
            alt=""
            style={{ objectFit: "contain", width: 280, height: 300 }}
            className={`${styles.clientsLogo}`}
          />
        </div>
        <div>
          <img
            src={IBA}
            alt=""
            style={{ objectFit: "contain", width: 280, height: 300 }}
            className={`${styles.clientsLogo}`}
          />
        </div>
        <div>
          <img
            src={PC}
            alt=""
            style={{ objectFit: "contain", width: 280, height: 300 }}
            className={`${styles.clientsLogo}`}
          />
        </div>
        <div>
          <img
            src={Suparco}
            alt=""
            style={{ objectFit: "contain", width: 280, height: 300 }}
            className={`${styles.clientsLogo}`}
          />
        </div>
      </Slider>
    </div>
  );
};

export default Clients;
