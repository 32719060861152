import React from "react";
import styles from "../../pages/Home/styles.module.css";
import { primaryColor, secondaryColor } from "../../constants/theme";
import { Tweet } from "react-twitter-widgets";

const ProjectManagement = () => {
  return (
    <div className="" style={{ marginTop: 90, marginBottom: 100 }}>
      <h1
        style={{ color: secondaryColor }}
        className={`${styles.fullStackHeading} h1-responsive text-center mb-2`}
      >
        News and Insights
      </h1>
      <p
        style={{ color: primaryColor, fontFamily: "Rajdhani", fontSize: 20, fontWeight:'500' }}
        className={`mx-1 text-center`}
      >
        Stay connected with us on our twitter account to get latest news in the
        industry
      </p>
      <div
        style={{ position: "relative", marginTop: 30 }}
        className="container d-flex justify-content-center"
      >
        <a
          class="twitter-timeline"
          data-width="400"
          data-height="500"
          data-theme="dark"
          href="https://twitter.com/SmkAssociates?ref_src=twsrc%5Etfw"
        >
          Tweets by SmkAssociates
        </a>{" "}
        <script
          async
          src="https://platform.twitter.com/widgets.js"
          charset="utf-8"
        ></script>{" "}
      </div>
    </div>
  );
};

export default ProjectManagement;
