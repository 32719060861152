import React from "react";
import styles from "../../pages/Home/styles.module.css";
import slider1 from "../../assets/images/slider1.jpg";
import slider2 from "../../assets/images/slider2.jpg";
import slider3 from "../../assets/images/slider3.jpg";
import slider4 from "../../assets/images/slider4.jpg";
import { primaryColor, secondaryColor } from "../../constants/theme";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";

const HeroSection = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
  };
  return (
    <Slider style={{ overflowX: "hidden",  }} {...settings}>
      <div>
        <div
          className={`d-flex justify-content-center align-items-center ${styles.cont_hero}`}
          style={{ backgroundImage: `url(${slider1})`,height:'100vh', height:'100vh' }}
        >
          <div className="container my-4">
            <div className="row">
              <div
                className={`col-md-9 white-text text-center text-md-left mt-xl-5 mb-5 ${styles.hero_mob_text}`}
                data-wow-delay="0.3s"
              >
                <h1
                  className={`h1-responsive font-weight-bold mt-sm-5 ${styles.hero_heading}`}
                >
                  A Vision of <br /> Better Building{" "}
                </h1>
                <h6 className={`mb-4 my-4 ${styles.subTxt_hero}`}>
                  Civil and Structural Engineering Solutions.
                </h6>
                <NavLink
                  className="btn"
                  style={{
                    backgroundColor: primaryColor,
                    fontFamily: "Rajdhani",
                    fontWeight: "500",
                    fontSize: 16,
                    textTransform: "capitalize",
                    color: "#fff",
                  }}
                  to="/about"
                >
                  Learn more
                </NavLink>
                <NavLink
                  className="btn"
                  style={{
                    backgroundColor: secondaryColor,
                    fontFamily: "Rajdhani",
                    fontWeight: "500",
                    fontSize: 16,
                    textTransform: "capitalize",
                    color: "#fff",
                  }}
                  to="/contact"
                >
                  Contact us
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div>
        <div
          className={`d-flex justify-content-center align-items-center ${styles.cont_hero}`}
          style={{ backgroundImage: `url(${slider4})`,height:'100vh' }}
        >
          <div className="container my-4">
            <div className="row">
              <div
                className="col-md-9 white-text text-center text-md-left mt-xl-5 mb-5"
                data-wow-delay="0.3s"
              >
                <h1
                  className={`h1-responsive font-weight-bold mt-sm-5 ${styles.hero_heading}`}
                >
                  We Care For <br /> Our Customers
                </h1>
                <h6 className={`mb-4 my-4 ${styles.subTxt_hero}`}>
                Providing best service to our Clients, On-Time and On-Budget
                </h6>
                <NavLink
                  className="btn"
                  style={{
                    backgroundColor: primaryColor,
                    fontFamily: "Rajdhani",
                    fontWeight: "500",
                    fontSize: 16,
                    textTransform: "capitalize",
                    color: "#fff",
                  }}
                  to="/about"
                >
                  Learn more
                </NavLink>
                <NavLink
                  className="btn"
                  style={{
                    backgroundColor: secondaryColor,
                    fontFamily: "Rajdhani",
                    fontWeight: "500",
                    fontSize: 16,
                    textTransform: "capitalize",
                    color: "#fff",
                  }}
                  to="/contact"
                >
                  Contact us
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div>
        <div
          className={`d-flex justify-content-center align-items-center ${styles.cont_hero}`}
          style={{ backgroundImage: `url(${slider2})`,height:'100vh' }}
        >
          <div className="container my-4">
            <div className="row">
              <div
                className="col-md-9 white-text text-center text-md-left mt-xl-5 mb-5"
                data-wow-delay="0.3s"
              >
                <h1
                  className={`h1-responsive font-weight-bold mt-sm-5 ${styles.hero_heading}`}
                >
                  Executing Pakistan’s <br /> Mega Projects
                </h1>
                <h6 className={`mb-4 my-4 ${styles.subTxt_hero}`}>
                  Complex Engineering Solutions.
                </h6>
                <NavLink
                  className="btn"
                  style={{
                    backgroundColor: primaryColor,
                    fontFamily: "Rajdhani",
                    fontWeight: "500",
                    fontSize: 16,
                    textTransform: "capitalize",
                    color: "#fff",
                  }}
                  to="/about"
                >
                  Learn more
                </NavLink>
                <NavLink
                  className="btn"
                  style={{
                    backgroundColor: secondaryColor,
                    fontFamily: "Rajdhani",
                    fontWeight: "500",
                    fontSize: 16,
                    textTransform: "capitalize",
                    color: "#fff",
                  }}
                  to="/contact"
                >
                  Contact us
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          className={`d-flex justify-content-center align-items-center ${styles.cont_hero}`}
          style={{ backgroundImage: `url(${slider3})`,height:'100vh' }}
        >
          <div className="container my-4">
            <div className="row">
              <div
                className="col-md-9 white-text text-center text-md-left mt-xl-5 mb-5"
                data-wow-delay="0.3s"
              >
                <h1
                  className={`h1-responsive font-weight-bold mt-sm-5 ${styles.hero_heading}`}
                >
                  Highly Skilled and <br /> Professional Team
                </h1>
                <h6 className={`mb-4 my-4 ${styles.subTxt_hero}`}>
                Providing Innovative & Creative Solutions
                </h6>
                <NavLink
                  className="btn"
                  style={{
                    backgroundColor: primaryColor,
                    fontFamily: "Rajdhani",
                    fontWeight: "500",
                    fontSize: 16,
                    textTransform: "capitalize",
                    color: "#fff",
                  }}
                  to="/about"
                >
                  Learn more
                </NavLink>
                <NavLink
                  className="btn"
                  style={{
                    backgroundColor: secondaryColor,
                    fontFamily: "Rajdhani",
                    fontWeight: "500",
                    fontSize: 16,
                    textTransform: "capitalize",
                    color: "#fff",
                  }}
                  to="/contact"
                >
                  Contact us
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Slider>
  );
};

export default HeroSection;
