import React from "react";
import CommonHeroSection from "../../components/commonHero";
import AboutSection from "../../components/Home/section-about";
import { primaryColor, secondaryColor } from "../../constants/theme";
import styles from "./styles.module.css";
import CommonTeamCard from "../../components/commonTeamCard";
import Footer from "../../components/Footer/footer";
import { TeamData } from "../../data/team-data";
import smkprofile from "../../assets/SMK-Profile.pdf";

const About = () => {
  return (
    <div>
      <CommonHeroSection heading="About Us" />

      <div style={{ marginTop: 100 }}>
        <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom:40
        }}
        className={`${styles.downloadHover}`}
      >
        <a download="SMK-Profile" href={smkprofile}>
          <h1
            style={{
              fontFamily: "Rajdhani",
              textAlign: "center",
              color: secondaryColor,
              fontWeight: "bold",
            }}
          >
            Download Company Profile
          </h1>
        </a>
        </div>
        <AboutSection />
      </div>
      <div
        className={`container ${styles.about_container}`}
        style={{ marginTop: 130, marginBottom: 70 }}
      >
        <div style={{ marginBottom: 40 }}>
          <h1
            className={styles.aboutHeadings}
            style={{ color: secondaryColor }}
          >
            Vision
          </h1>
          <p className={styles.aboutText} style={{ color: primaryColor }}>
            SMK is a firm of diverse expertise. We have designers, consultants,
            engineers, technical specialists and planners, etc. all working to
            cater to the landscape of the present and future. We come together
            to give our customers strategies that are innovative in bringing a
            solution to the issue that they are trying to address and at the
            same time are beneficial to a larger picture and long-term frame.
            With a one window solution, we aim to make our client work as easy
            as he wants with all required resources at his disposal.
          </p>
        </div>
        {/* ================================================ */}
        <div style={{ marginBottom: 30 }}>
          <h1
            className={styles.aboutHeadings}
            style={{ color: secondaryColor }}
          >
            Values
          </h1>
          <p className={styles.aboutText} style={{ color: primaryColor }}>
            Our values entail positively affecting all the parties our firm
            impacts through our work including our natural environment. We are
            currently striving to bring about a sustainable and environmentally
            conscious workflow. Honesty is a core value at our company that is
            instilled in our employees as well as our higher management. The
            importance of safeguarding the interests of those our company
            interacts with is highly stressed.
          </p>
          <p className={styles.aboutText} style={{ color: primaryColor }}>
            Even though, most of us see the speed of technological innovation,
            the truth in the field is that many inefficiencies are leading to
            obstructions. Our consultancy makes a point to tackle these. We are
            convinced there is a superior route to take.
          </p>
          <p className={styles.aboutText} style={{ color: primaryColor }}>
            By utilizing varieties of people and abilities of information
            technology, we have confidence we can aid our client to plan, submit
            and regulate infrastructure that supports the world to function
            finer for everyone. Our desire to make these needed changes is
            inspired by the vision that our children and grandchildren are going
            to utilize and live in these structures, in a society that would
            have been environmentally conscious historically.
          </p>
          <p className={styles.aboutText} style={{ color: primaryColor }}>
            We take in all facts and their evaluations to create a plan, whether
            that plan is to come up with a new solution altogether or a
            modification of previous actions of clients. Our focus is on the
            present for that is the only way to shape our future.
          </p>
        </div>
        {/* ================================================ */}
        <div style={{ marginBottom: 30 }}>
          <h1
            className={styles.aboutHeadings}
            style={{ color: secondaryColor }}
          >
            About Us
          </h1>
          <p className={styles.aboutText} style={{ color: primaryColor }}>
            Our advisors and analysts are both disruptors and allies, capable of
            seeing — and assisting others in seeing — a view of a future that
            works best for everyone. They enjoy listening, comprehending, and
            connecting their experiences to generate solutions. Then, using
            cutting-edge digital and computer tools, they collaborate with our
            clients to make the vision a reality.
          </p>
          <p className={styles.aboutText} style={{ color: primaryColor }}>
            We are also committed to diversifying the infrastructure market. We
            are transforming the shape of our business to make it more
            multicultural so that everyone can flourish and be their best. By To
            help you shape a better-lived experience for the future, our firm
            utilizes the expertise of some of Pakistan's leading architecture,
            civil & MEP engineering, and project management practitioners.
          </p>
          <p className={styles.aboutText} style={{ color: primaryColor }}>
            Our consultants, who are experts in their fields, collaborate with
            you to optimize preparation, execution, and service. We employ
            cutting-edge data-driven technology to achieve the best possible
            balance of cost, performance, and results for all stakeholders.
          </p>
          <p className={styles.aboutText} style={{ color: primaryColor }}>
            You have the option of collaborating with our firm at any point of
            the project, from original design to execution to process and
            optimization. When you do, our designers and project managers will
            ensure that all project phases are seamlessly integrated, delivering
            optimal efficiencies not just at - level but also through the whole
            project. Alternatively, you should get our company on board an
            ongoing project to focus on discrete points. Our programs are
            versatile, adaptable, and customizable to specific requirements. Our
            employees are natural teammates who can work with the teams and
            partners to solve difficult challenges and achieve optimal
            productivity.
          </p>
          <p className={styles.aboutText} style={{ color: primaryColor }}>
            We help to plan, deliver, and manage the infrastructure needed for a
            liveable and prosperous future by collaborating with clients and
            collaborators to leverage the expertise of our diverse teams, as
            well as the power of data and technology.
          </p>
        </div>
        {/* ====================================================================  */}
        <div style={{ marginTop: 50 }}>
          <h1
            className={styles.aboutHeadings}
            style={{ color: secondaryColor }}
          >
            Certificate
          </h1>
          <div className="mt-4">
            <ol>
              <li style={{ color: primaryColor }} className={styles.aboutText}>
                {" "}
                Certificate of Registration | Valid PEC Registration as Consulting Engineers
              </li>
              <li style={{ color: primaryColor }} className={styles.aboutText}>
                {" "}
                Capital Development Authority (Directorate of Structures)
                Authorized to Practice Structural Engineer (Building) in
                Islamabad.
              </li>
              <li style={{ color: primaryColor }} className={styles.aboutText}>
                {" "}
                Capital Development Authority Islamabad Design Wing to Practice
                as Design Vetting Consultant(Building)
              </li>
              <li style={{ color: primaryColor }} className={styles.aboutText}>
                {" "}
                Sindh Building Control Authority | Certificate For Registration
                in Category A
              </li>
              <li style={{ color: primaryColor }} className={styles.aboutText}>
                {" "}
                Sindh Building Control Authority | Structural Engineer License
              </li>
              <li style={{ color: primaryColor }} className={styles.aboutText}>
                {" "}
                Malir Cantonment Board |Structural Engineering License
              </li>
              <li style={{ color: primaryColor }} className={styles.aboutText}>
                {" "}
                Malir Cantonment Board |Vetting Engineering License
              </li>
              <li style={{ color: primaryColor }} className={styles.aboutText}>
                {" "}
                Member of IEP
              </li>
              <li style={{ color: primaryColor }} className={styles.aboutText}>
                {" "}
                Member of ACEP
              </li>
            </ol>
          </div>
        </div>
        <div style={{ marginBottom: 60, marginTop: 60 }}>
          <h1
            className={styles.aboutHeadings}
            style={{ color: secondaryColor }}
          >
            Our Team
          </h1>
          <div className="container">
            <div className="row">
              {TeamData.map((v, i) => {
                return (
                  <CommonTeamCard
                    name={v.name}
                    post={v.post}
                    para1={v.para1}
                    para2={v.para2}
                    para3={v.para3}
                    para4={v.para4}
                    para5={v.para5}
                    para6={v.para6}
                    color={v.color}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
      
      <div style={{ marginTop: 50 }}>
        <Footer />
      </div>
    </div>
  );
};

export default About;
