import React from "react";
import CommonHeroSection from "../../components/commonHero";
import Footer from "../../components/Footer/footer";
import Centaurus from "../../assets/images/logos/Centaurus.png";
import Dreamworld from "../../assets/images/logos/Dreamworld.png";
import CAA from "../../assets/images/logos/CAA.png";
import GulAhmed from "../../assets/images/logos/Gul-Ahmed.png";
import HOM from "../../assets/images/logos/HOM.png";
import Hyatt from "../../assets/images/logos/Hyatt.png";
import IBA from "../../assets/images/logos/IBA.png";
import Mcdonalds from "../../assets/images/logos/Mcdonalds.png";
import PC from "../../assets/images/logos/PC.png";
import PIA from "../../assets/images/logos/PIA.png";
import PMI from "../../assets/images/logos/PMI.png";
import PTC from "../../assets/images/logos/PTC.png";
import Suparco from "../../assets/images/logos/Suparco.png";
import PakArmy from "../../assets/images/logos/Pak-army.png";
import PakNavy from "../../assets/images/logos/pak-navy.png";
import FaujiFoundation from "../../assets/images/logos/Fauji-foundation.png";
import Pepsi from "../../assets/images/logos/pepsi.png";
import PakAirforce from "../../assets/images/logos/pak-airforce.png";
import { primaryColor } from "../../constants/theme";

const CLientsPage = () => {
  const logosData = [
    {
      img: Centaurus,
    },
    {
      img: HOM,
    },

    {
      img: PakNavy,
    },
    {
      img: CAA,
    },
    {
      img: PakAirforce,
    },
    {
      img: GulAhmed,
    },

    {
      img: Hyatt,
    },
    {
      img: IBA,
    },
    {
      img: PakArmy,
    },
    {
      img: Mcdonalds,
    },
    {
      img: PC,
    },
    {
      img: PMI,
    },
    {
      img: FaujiFoundation,
    },
    {
      img: PIA,
    },
    {
      img: Dreamworld,
    },
    {
      img: PTC,
    },
    {
      img: Pepsi,
    },
    {
      img: Suparco,
    },
  ];
  return (
    <div>
      <CommonHeroSection heading="Our Clients" />
      <div className="container" style={{ marginTop: 90, marginBottom: 90 }}>
        <h5
          style={{
            fontFamily: "Yantramanav",
            fontSize: 21,
            textAlign: "center",
            fontWeight: "500",
            color: primaryColor,
          }}
        >
          We have worked closely and successfully with some of the biggest names
          in key public and private organizations, and pride ourselves on
          meeting and exceeding our clients expectations. Below, you can find a
          selection of the many client organizations we have served.
        </h5>
        <div className="row">
          {logosData.map((v, i) => {
            return (
              <div key={i} className="col-md-4 col-sm-6 col-6 mt-3">
                <div>
                  <img
                    src={v.img}
                    style={{ width: "100%", height: "80%" }}
                    alt=""
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default CLientsPage;
