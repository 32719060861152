import { primaryColor, secondaryColor } from "../constants/theme";
import CreatingValues from "../assets/images/Creating-values.png";
import OneStopService from "../assets/images/One-Stop-Service.png";
import Sustainability from "../assets/images/Sustainability.png";
import OneStop from '../assets/images/one-stop.jpg'
import Multi from '../assets/images/multi-vision.jpg'
import consult from '../assets/images/360.jpg'

export const section2Services = [
  {
    textBlue: "One Stop Services",
    textOrange: "All Services under one roof",
    para1:
      "Our clients’ comfort is a foremost priority for us and something that we pride ourselves in. It is important to us that we deliver expertise in all areas of a project. After coming into our hands, our valued clients can rightly relax their worries regarding their project. A trademark of our firm’s service is to assure our clientele of the trust they can place in us. Not only is it an efficient practice to house various kinds of expertise in a company but as our clients will happily come to realize, it is an extremely cost-effective method too ",
    para2:
      "The various benefits that come along in partnering with our multi-service firm include, the prevention of miscommunication and delays etc.",
    para3:
      "In addition to this, a single party will hold responsibility for all actions undertaken as well as for finding or creating solutions to any roadblocks.",
    para4:
      "Our firm is not just an expert in starting a project from scratch rather we are ready at any moment to take over any existing project in order to drive it forward from any stage.",
    para5:
      "The apt parallel we can give of our intricate workings is that not only do we have experience of laying foundation stones rather we can be trusted with the restoration of existing old structures just as well.",
    color: primaryColor,
    fontSize: 36,
    img: OneStopService,
    bgImg: OneStop,
    heading:'All Services Under One Roof'
    // text: "Comprehensive engineerig and consulting services under one roof throughout the project cycle.",
  },
  {
    textBlue: "Multi-Vision Work",
    textOrange: "Experts in Residential and Commercial Construction",
    para1:
      "Aiming to be the most cost effective that we can, we strive towards best results for your investment. Our multi-vision work does not fail to take into account the short and long term environmental impact any work of this nature entails. Further adding to this attention to detail, it is vital for us as per our company’s value statements to ethically self-regulate ourselves in being respectful of an area’s legislation as well our industry’s latest codes. ",
    para2:
      "Our strong use of design and material complements our construction trademarks of being earthquake proof as well as wind resistant. Our history of work proves the visually stunning work our designers provide.",
    para3:
      "In addition to taking on commercial projects, we are well skilled in building the house of your dream for you and your loved ones as well as high rise buildings and factories at truly competitive rates.",
    para4: 'It is worth mentioning that during the Earthquake that occurred in October 2015, none of the building structures designed/vetted by us, existing in the Earthquke Zone were affected',
      color: secondaryColor,
    fontSize: 36,
    img: CreatingValues,
    bgImg: Multi,
    heading:'Expert in Residential, Commercial & Industrial Construction'
    // text: "We are specialized in construction of world-class residential and commercial buildings",
  },
  {
    textBlue: "360° Consultancy",
    textOrange: "Full-Spectrum Engineering Consultants",
    para1:
      "Established in the mid-seventies as a civil and structural engineering consultancy firm, SMK has emereged as one of the leading consultancy companies in Pakistan. ",
    para2:
      "Having served a huge number of local and international clients, SMK Associates have gained valuable experience in providing civil and structural engineering services including:",
    color: primaryColor,
    fontSize: 36,
    img: Sustainability,
    bgImg: consult,
    heading:'Civil & Structural Consultation'
    // text: "SMK Associates emerged as a  full-spectrum engineering consultant in the industry.",
  },
];
