import Advisory from "../assets/images/Advisory.jpg";
import Architecture from "../assets/images/Architecture.jpg";
import BuildingDesign from "../assets/images/building-design.jpg";
import ConstructionManagement from "../assets/images/construct-management.jpg";
import ElecServices from "../assets/images/elec-services.jpg";
import FireEngr from "../assets/images/fire-engr.jpg";
import MechanicalServices from "../assets/images/mech-services.jpg";
import LiftInstall from "../assets/images/lift-install.jpg";
import SoilTesting from "../assets/images/soil-testing.jpg";

export const servicesData = [
    {
        service: "Advisory",
        img: Advisory
    },
    {
        service: "Architecture",
        img: Architecture
    },
    {
        service: "Structural Design",
        img: BuildingDesign
    },
    {
        service: "Construction Management",
        img: ConstructionManagement
    },
    {
        service: "Sub-Soil Testing",
        img: SoilTesting
    },
    {
        service: "Mechanical Services",
        img: MechanicalServices
    },
   
]