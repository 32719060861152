import React from "react";
import Footer from "../../components/Footer/footer";
import Clients from "../../components/Home/clients";
import FeaturedProjects from "../../components/Home/featured-projects-section";
import FullStackServices from "../../components/Home/full-stack-services";
import HeroSection from "../../components/Home/hero-section";
import ProjectManagement from "../../components/Home/project-management";
import AboutSection from "../../components/Home/section-about";
import SectionCard from "../../components/Home/section-cards";
import "./styles.module.css";

const Home = () => {
  return (
    <>
      <div>
        <HeroSection />
      </div>
      <SectionCard />
      <AboutSection homepage="home" />
      <FeaturedProjects />
      <FullStackServices />
      <ProjectManagement />
      <Clients />
      <Footer />
    </>
  );
};

export default Home;
