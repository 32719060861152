import React from "react";
import { NavLink } from "react-router-dom";
import FooterBg from "../../assets/images/bg.png";
import Logo from "../../assets/images/logo.png";
import styles from "../../pages/Home/styles.module.css";

const Footer = () => {
  return (
    <footer
      className="page-footer font-small "
      style={{
        backgroundImage: `url(${FooterBg})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        overflow: "hidden",
        width: "hidden",
        height: "100%",
      }}
    >
      <div className="container text-center text-md-left p-4">
        <div className="row">
          <div className="col-md-4 d-flex justify-content-center   flex-column mx-auto">
            <div
              style={{ width: "50%" }}
              className={`${styles.footerLogo} bg-white `}
            >
              <NavLink
                to="/"
                style={{ width: 150, textDecoration: "none" }}
                className="mb-3  h-100 p-2   mt-2"
              >
                <img
                  src={Logo}
                  className="mx-4"
                  style={{ objectFit: "contain", cursor: "pointer" }}
                  height="80"
                  width="90"
                  alt=""
                />
              </NavLink>
            </div>
            <p
              className="mt-2"
              style={{
                fontFamily: "Yantramanav",
                fontSize: 18,
                fontWeight: "500",
              }}
            >
              SMK Associates is the leading civil and structural engineering
              consultancy firm in the region. Equipped with valuable experience
              by working on both international and local projects, we provide
              full-stack civil and structural engineering services.
            </p>
          </div>

          <hr className="clearfix w-100 d-md-none" />

          <div className="col-md-4 d-flex  align-items-center flex-column mx-auto">
            <h5
              className={`font-weight-bold text-uppercase mt-3 mb-2 ${styles.footerHeading}`}
            >
              Useful Links
            </h5>

            <ul className="list-unstyled">
              <li className="mb-3 text-left">
                <a href="https://www.facebook.com/SMK-Associates-102979235375444">
                  <p className={`${styles.footerTxt}`}>Facebook Page</p>
                </a>
              </li>
              <li className="mb-3 text-left">
                <a href="https://www.linkedin.com/company/smkassociates">
                  <p className={`${styles.footerTxt}`}>Linkedin Profile</p>
                </a>
              </li>
              <li className="mb-3 text-left">
                <a href="https://twitter.com/SmkAssociates">
                  <p className={`${styles.footerTxt}`}>Twitter Profile</p>
                </a>
              </li>
              <li className="mb-3 text-left">
                <a href="https://www.instagram.com/smkassociates/">
                  <p className={`${styles.footerTxt}`}>Instagram Profile</p>
                </a>
              </li>
            </ul>
          </div>

          <hr className="clearfix w-100 d-md-none" />

          <div className="col-md-4 d-flex  flex-column mx-auto">
            <div style={{ marginTop: -14 }}>
              <h5
                className={`font-weight-bold text-uppercase mt-3 mb-2 ${styles.footerHeading}`}
              >
                Contact Information
              </h5>
              <p className={`text-white ${styles.footerTxt} `}>
                Office # 503-504, 5th Floor, <br />
                Noor Estate Shahra-e-Faisal, <br />
                Karachi, Pakistan
              </p>
            </div>
            <div style={{ marginTop: -14 }}>
              <h5
                className={`font-weight-bold text-uppercase mt-3 mb-2 ${styles.footerHeading}`}
              >
                Phone
              </h5>
              <p className={`text-white ${styles.footerTxt}`}>
                +92-21-34522516 | +92-21-34522570
              </p>
              {/* <p className={`text-white ${styles.footerTxt}`}>
                +92-21-34522570
              </p> */}
            </div>
            <div style={{ marginTop: -14 }}>
              <h5
                className={`font-weight-bold text-uppercase mt-3 mb-2 ${styles.footerHeading}`}
              >
                Email
              </h5>
              <p className={`text-white ${styles.footerTxt}`}>
              smkengineer@gmail.com{" "}
              </p>
            </div>
          </div>

          <hr className="clearfix w-100 d-md-none" />
        </div>
      </div>
      <div style={{fontSize:18, fontFamily:'Rajdhani'}} className="footer-copyright px-2 text-center py-3">© Copyright 2021 | SMK Associates | All Rights Reserved <br />
    <a style={{fontWeight:'500'}} href="http://www.egriptechnologies.com/"> Designed and Developed by E-GRIP Technologies</a>
  </div>
    </footer>
  );
};

export default Footer;
