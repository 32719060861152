import { primaryColor, secondaryColor } from "../constants/theme";

export const TeamData = [
  
  {
    name: "S. M Kazmi",
    post: " Chief Executive",
    para1:
      "Mr. S. M. Kazmi holds the charge of an entire engineering department, directing the teams as they work on and complete their projects. He is humble enough to works alongside other engineers, draftsmen and other technicians while approving designs, estimating costs, negotiating contracts and executing plans safely and vigorously. ",
    para2:
      "Mr. Kazmi formed the company in 1976 after coming back from US where he worked as a structural design engineer at A.M. Kenny & Associates, Ohio. He completed his master’s degree from the University of Cincinnati, U.S.A in 1974. However, his roots lay back within national land as he acquired his earlier education from Pakistan; he completed his B.E in civil engineering from NED University (1970).",
    para3:
      "He has been actively involved in various engineering councils and institutes across the globe. Some prominent ones include American Concrete Institute, Pakistan Engineering Council, American Institute of Restressed Concrete and Institution of Engineers of Pakistan. ",
    para4:
      "He is committed to working in close coordination with his clients and associates to have a thorough understanding of their requirements and objectives and to subsequently give them modern solutions that could face present and future challenges. His best-in-class capabilities and technical skills make him a hard competition to meet. His infrastructure designs consider various aspects of the overall frame; primarily the economics of the project while enduring sustainability.  ",
    para5:
      "Mr. Kazmi is among highly respected design engineers in Pakistan engineering market having worked on many national and international projects. He has worked on both private and government projects, the prominent ones include the international airport of Singapore and nuclear power plant building in Kohat.",
    para6:
      "He has been integral to the important advances the company has made during recent years. We are grateful to Mr. Kazmi as his passion for work is the leading factor for the continuous growth of SMK Associates and through his keenness we could predict bigger growth graph of the company in the future years.",
    color: primaryColor,
  },

  {
    name: "Shazia Hussain",
    post: " Managing Director",
    para1:
      "She is best described by her colleagues as passionate. She is insistently committed to technical excellence in structural engineering while at the same she is well aware of industry. She brings 25 years of experience in the design of civil structures including, but not limited to, residential &amp; commercial buildings, factories, bridges, hospitals and bungalows. Other than working on various projects she has also performed analysis on a variety of existing structures to determine capacity and performance characteristics and subsequently provide recommendation for remedial work.",
    para2:
      "Her education includes attendance at NED University of engineering and technology where she received her bachelor’s degree in Civil engineering. From that point onwards her journey in the professional industry of structural engineering enriched her extensive experience in the field as she worked on various mega projects across the city as well as internationally. She is also an active member of Pakistan Engineering Council.",
    para3:
      "She has inspiring technical ventures under her hat, yet humility is what her colleagues enjoy from her side. She also volunteers her time as a mentor with MGYW, a non-profit organization that works to deliver quality education to under privileged girls across Pakistan. Considering her profound knowledge of the required technical work, she is great to work with. Everyone on the team takes an inspiration from her as she possess splendid skills to produce best results out of tricky projects. She is an invaluable asset of SMK.",
    color: secondaryColor,
  },
  {
    name: "Sualeha Kishwar",
    post: "Director",
    para1:
      "Instrumental in leading and managing crucial projects. She is an accomplished project manager and structural design engineer and supervisor. She is an active member of Pakistan Engineering Council (PEC) and also Institute of engineers of Pakistan (IEP). She is central in the design of warehouses, buildings, factories and safety improvements. She is veteran in in the complete project delivery process from concept feasibility, planning, design, construction, and maintenance – including procurement and right- of-way acquisition.",
    para2:
      "Aside from her profound endeavors, she is mentor to many young and professional engineers. She has been generous to open up 30% more positions for internship in the firm in comparison to past intake of interns. She’s been enthusiastic about her role and always yearns to see young employees growing professiona ",
    para3:
      "Her aspirations to see the company growing has been the driving force in the development and progression of the expansion. She has a way of seeing projects from conception to completion while keeping clients’ demands and satisfaction at an utmost priority. Her unique perspective makes the things done in their best way. The pride that she takes in her work is truly inspiring.",
    color: primaryColor,
  },
];
