import React, { useState } from 'react'
import { primaryColor } from '../../constants/theme'

const ServicePageCard = ({text, valueS, setValue, value}) => {

  const contentShow = () => {
     setValue(valueS)
  }
    return (
        <div
        style={{
          backgroundColor: primaryColor,
          width: "100%",
          // marginBottom: 2,
          cursor:'pointer'
        }}
        onClick={contentShow}
      >
        <h5
          className={`text-center text-white py-3`}
          style={{
            fontFamily: "Rajdhani",
            fontSize: 28,
            fontWeight: "600",
          }}
        >
          {text}
        </h5>
      </div>
    )
}

export default ServicePageCard
