import React from "react";
import CommonHeroSection from "../../components/commonHero";
import { primaryColor, secondaryColor } from "../../constants/theme";
import styles from "../About/styles.module.css";
import Footer from "../../components/Footer/footer";

const Contact = () => {
  return (
    <div>
      <CommonHeroSection heading="Contact Us" />
      <div className="my-5">
        <div className="container">
          <h1
            className={`${styles.contactPageHeading} my-4`}
            style={{ color: primaryColor }}
          >
            Get in Touch With Us <br /> For Your Latest Project
          </h1>
          <h1
            className={`${styles.contactPageHeadingMob} mt-5 mb-4`}
            style={{ color: primaryColor }}
          >
            Get in Touch With Us For Your Latest Project
          </h1>

          <div
            className={`row ${styles.contact_container}`}
            style={{ marginTop: 80 }}
          >
            <div className="col-md-4 d-flex justify-content-center align-items-center flex-column mt-3">
              <h3
                style={{ color: secondaryColor }}
                className={`${styles.contactOrangeTxt} pt-4`}
              >
                Office Address
              </h3>
              <div style={{ minHeight: 120 }}>
                <p
                  style={{ color: primaryColor }}
                  className={`${styles.contactBlueTxt}`}
                >
                  Office # 503-504, 5th Floor, <br />
                Noor Estate Shahra-e-Faisal, <br />
                Karachi, Pakistan
                </p>
              </div>
            </div>
            <div className="col-md-4 d-flex justify-content-center align-items-center flex-column mt-2">
              <h3
                style={{ color: secondaryColor }}
                className={`${styles.contactOrangeTxt}`}
              >
                Phone
              </h3>
              <div style={{ minHeight: 120 }}>
                <p
                  style={{ color: primaryColor }}
                  className={`${styles.contactBlueTxt}`}
                >
                  +92-21-34522516 <br />
                  +92-21-34522570
                </p>
              </div>
            </div>
            <div className="col-md-4 d-flex justify-content-center align-items-center flex-column mt-2">
              <h3
                style={{ color: secondaryColor }}
                className={`${styles.contactOrangeTxt}`}
              >
                Email
              </h3>
              <div style={{ minHeight: 120 }}>
                <p
                  style={{ color: primaryColor }}
                  className={`${styles.contactBlueTxt}`}
                >
                  smkengineer@gmail.com
                </p>
                <p
                style={{ color: primaryColor, marginTop: -50 }}
                className={`${styles.contactBlueTxt}`}
                >
                admin@smkassociates-pk.com
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ marginTop: 40, marginBottom: 60 }}
          className="container d-flex justify-content-center align-items-center"
        >
          <div style={{ width: "100%" }}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d904.9829380806634!2d67.08131122916575!3d24.866180415777542!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33ea581539265%3A0xf702ffc6110afcbc!2sSMK%20Associates!5e0!3m2!1sen!2s!4v1624611071809!5m2!1sen!2s"
              width="100%"
              height="450"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
