import React from "react";
import { NavLink } from "react-router-dom";
import styles from "../../pages/Home/styles.module.css";

const CommonTeamCard = ({
  name,
  post,
  para1,
  para2,
  para3,
  para4,
  para5,
  para6,
  color,
}) => {
  return (
    <div className="col-md-4 col-lg-4 col-sm-12 col-12 mt-3">
      <NavLink
        className={`card py-4 ${styles.teamCard}`}
        style={{
          backgroundColor: color,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          color: "#fff",
        }}
        to={{
          pathname: `/member/${name}`,
          state: { post, para1, para2, para3, para4, para5, para6 },
        }}
      >
        <div className="card-body">
          <h2
            style={{
              fontWeight: "bold",
              color: "#fff",
              textAlign: "center",
              fontFamily: "Rajdhani",
              fontSize: 34,
            }}
          >
            {name}
          </h2>
          <div
            style={{
              height: 3,
              backgroundColor: "#fff",
              opacity: 0.5,
              borderRadius: 100,
              marginTop: -10,
            }}
          />
          <p
            style={{
              fontWeight: "400",
              color: "#fff",
              textAlign: "center",
              fontFamily: "Yantramanav",
              fontSize: 36,
              marginTop: 10,
            }}
          >
            {post}
          </p>
        </div>
      </NavLink>
    </div>
  );
};

export default CommonTeamCard;
