import React from "react";
import { CardData } from "../../constants/Data/section2-card-data";
import CommonCard from "./common-card";
import styles from "../../pages/Home/styles.module.css";
import { section2Services } from "../../data/section2-services";
const SectionCard = () => {
  return (
    <div
      className={`container ${styles.section_card_cont}`}
      style={{ marginTop: 70, marginBottom: 90 }}
    >
      <div className="row">
        {section2Services.map((v, i) => (
          <CommonCard
            key={i}
            textBlue={v.textBlue}
            textOrange={v.textOrange}
            fontSize={v.fontSize}
            color={v.color}
            para1={v.para1}
            para2={v.para2}
            para3={v.para3}
            para4={v.para4}
            para5={v.para5}
            img={v.img}
            bgImg={v.bgImg}
            text={v.text}
            heading={v.heading}
          />
        ))}
      </div>
    </div>
  );
};

export default SectionCard;
