import React, { useState } from "react";
import CommonHeroSection from "../../components/commonHero";
import ServicePageCard from "../../components/servicesPageCommonCard";
import styles from "../About/styles.module.css";
import { primaryColor, secondaryColor } from "../../constants/theme";
import Footer from "../../components/Footer/footer";
import Advisory from "../../assets/images/Advisory.jpg";
import Architecture from "../../assets/images/Architecture.jpg";
import BuildingDesign from "../../assets/images/building-design.jpg";
import ConstructionManagement from "../../assets/images/construct-management.jpg";
import ElecServices from "../../assets/images/elec-services.jpg";
import FireEngr from "../../assets/images/fire-engr.jpg";
import MechanicalServices from "../../assets/images/mech-services.jpg";
import LiftInstall from "../../assets/images/lift-install.jpg";
import SoilTesting from "../../assets/images/soil-testing.jpg";

const Services = () => {
  const [value, setValue] = useState("Architecture");

  return (
    <div>
      <CommonHeroSection heading="Our Services" />
      <div className="container" style={{ marginTop: 90, marginBottom: 30 }}>
        <div className="row">
          <div className="col-md-12 col-12 col-sm-12 col-lg-4 mt-4">
            <ServicePageCard
              value={value}
              setValue={setValue}
              valueS="Architecture"
              text="Architecture"
            />
            <ServicePageCard
              value={value}
              setValue={setValue}
              valueS="BuildingDesign"
              text="Structural Design"
            />
            <ServicePageCard
              value={value}
              setValue={setValue}
              valueS="ConstructionManagement"
              text="Construction Management"
            />
            <ServicePageCard
              value={value}
              setValue={setValue}
              valueS="AdvisoryServices"
              text="Advisory Services"
            />
            <ServicePageCard
              value={value}
              setValue={setValue}
              valueS="elecEngr"
              text="Electrical Services"
            />
            <ServicePageCard
              value={value}
              setValue={setValue}
              valueS="mechEngr"
              text="Mechanical Engineering"
            />
            <ServicePageCard
              value={value}
              setValue={setValue}
              valueS="fireEngr"
              text="Fire Fighting"
            />
            <ServicePageCard
              value={value}
              setValue={setValue}
              valueS="soilTesting"
              text="Sub-Soil Testing"
            />
            <ServicePageCard
              value={value}
              setValue={setValue}
              valueS="Lift"
              text="Lift Installation Services"
            />
          </div>
          
          <div className="col-md-12 col-12 col-sm-12 col-lg-8">
          <h4
          style={{
            fontFamily:'Rajdhani',
            textAlign:'center',
            fontWeight:'600',
            color: secondaryColor,
            letterSpacing:1,
          }}
          >
              {
                 value === "Architecture"
                 ? 'Architecture'
                 : value === "BuildingDesign"
                 ? 'Structural Design'
                 : value === "ConstructionManagement"
                 ? 'Construction Management'
                 : value === "AdvisoryServices"
                 ? 'Advisory'
                 : value === "elecEngr"
                 ? 'Electrical Services'
                 : value === "mechEngr"
                 ? 'Mechanical Services'
                 : value === "fireEngr"
                 ? 'Fire Fighting'
                 : value === "soilTesting"
                 ? 'Sub-Soil Testing'
                 : value === "Lift"
                 ? 'Lift Installation Services'
                 : 'Architecture'
              }
            </h4>
            <img
              src={
                value === "Architecture"
                  ? Architecture
                  : value === "BuildingDesign"
                  ? BuildingDesign
                  : value === "ConstructionManagement"
                  ? ConstructionManagement
                  : value === "AdvisoryServices"
                  ? Advisory
                  : value === "elecEngr"
                  ? ElecServices
                  : value === "mechEngr"
                  ? MechanicalServices
                  : value === "fireEngr"
                  ? FireEngr
                  : value === "soilTesting"
                  ? SoilTesting
                  : value === "Lift"
                  ? LiftInstall
                  : Architecture
              }
              className={`${styles.servicesPageImg}`}
              style={{ objectFit: "contain" }}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="container" style={{ marginTop: 50, marginBottom: 70 }}>
        {value === "Architecture" ? (
          <div style={{ marginBottom: 40 }}>
            <h1
              className={styles.aboutHeadings}
              style={{ color: secondaryColor }}
            >
              Architecture
            </h1>
            <p className={styles.aboutText} style={{ color: primaryColor }}>
              Our buildings carry hallmarks of efficiency and beauty. Our
              philosophy is that architecture should always be centered around
              people. We‘ve always believed that environments shape minds. The
              latest technology is used to achieve aims in the most
              cost-effective of methods. It's an approach that has helped us to
              build sustainable commercial projects, link cities with reliable
              transportation networks, and provide the latest research and
              organizational facilities.
            </p>
          </div>
        ) : value === "BuildingDesign" ? (
          <div style={{ marginBottom: 30 }}>
            <h1
              className={styles.aboutHeadings}
              style={{ color: secondaryColor }}
            >
              Building Design
            </h1>
            <p className={styles.aboutText} style={{ color: primaryColor }}>
              The importance of efficiency cannot be overstated. As a result,
              our structural engineers and integrated architecture teams use
              fewer components in their buildings. And, to construct buildings
              with lower operating costs and fewer materials, we strive for
              innovation in well-functioning appearances as well as mechanical,
              electrical, water, and waste systems.
            </p>
            <p className={styles.aboutText} style={{ color: primaryColor }}>
              Through ingraining technology in structures, we help inhabitants
              interact, mingle, and collaborate, and we facilitate their
              security by designing sophisticated defense, fire, seismic, wind,
              and lift systems. People desire structures that both stimulate and
              entertain them. Our designers create beautiful effects and playful
              shapes as both inner and outer forms of structures. Above
              everything, they integrate all elements – from shape to sounds,
              illumination, and greenery – to create buildings that are in sync
              with their landscapes and their occupants.
            </p>
            <p className={styles.aboutText} style={{ color: primaryColor }}>
              Our company’s comprehensive strategies to building knowledge
              modeling, construction mechanics, and fluid dynamics are the
              driving force behind the improved efficiency of our building
              designs. Our structure planners do advanced study and testing, but
              still with a specific goal in mind.
            </p>
            <p className={styles.aboutText} style={{ color: primaryColor }}>
              Our property maintenance and operations experts ensure that a
              building continues to function optimally throughout its existence.
              Our building retrofit experts breathe new life into
              underperforming assets. Our company’s architecture teams can
              collaborate to create seamless environmental building design, lead
              dynamic multidisciplinary programs, or create living and working
              environments for entire communities.
            </p>
          </div>
        ) : value === "ConstructionManagement" ? (
          <div style={{ marginBottom: 30 }}>
            <h1
              className={styles.aboutHeadings}
              style={{ color: secondaryColor }}
            >
              Construction Management
            </h1>
            <p className={styles.aboutText} style={{ color: primaryColor }}>
              Experience, competence, quality, innovation, and sustainability
              are the pillars of SMK's success in the execution of significant
              construction projects. <br /> Our services are available in all
              areas of the building sector. Our specialist organizations offer
              value for our clients by combining and managing the most
              diversified services. We bring people, materials, and ideas
              together.
            </p>
            <p className={styles.aboutText} style={{ color: primaryColor }}>
              machines in the right place and the right time to complete even
              the most complicated construction projects on time, with the
              finest quality, and at the greatest price In a nutshell, we accept
              a portion of the risk, relieving our clients.
            </p>
            <p className={styles.aboutText} style={{ color: primaryColor }}>
              We are one of the only firms capable of providing services across
              the whole construction value chain – from design to planning,
              construction to property and facility services, operation to
              demolition. <br /> When we plan our future and distribute our
              resources – such as financial means or staff abilities – across
              the organization, we keep our goal statement in mind. Furthermore,
              we allow the following principles to influence all of our
              decisions:
            </p>
          </div>
        ) : value === "AdvisoryServices" ? (
          <div style={{ marginBottom: 30 }}>
            <h1
              className={styles.aboutHeadings}
              style={{ color: secondaryColor }}
            >
              Advisory Services
            </h1>
            <p className={styles.aboutText} style={{ color: primaryColor }}>
              In the dynamic environments of today, clients come to us with the
              expectation of game-changing ideas. Often those game-changing
              ideas rest on transforming the client experience. No matter which
              issue has been presented before us, we put research and empirical
              evidence to look for the most cutting-edge solutions.
            </p>
            <p className={styles.aboutText} style={{ color: primaryColor }}>
              We show firms that their desired outcomes are efficiently
              operationalizable. Trend forecasting is always part of the package
              as we ensure our solutions remain viable both in the short and
              long term.
            </p>
            <p className={styles.aboutText} style={{ color: primaryColor }}>
              Having a finger on the pulse of the market lets us know what steps
              to take and which ones to avoid. All of this requires a deep
              appreciation of a given business model and its inner and outer
              workings. We work in collaboration with our clients to ensure that
              their work culture becomes an ingrained part of the process being
              followed.
            </p>
          </div>
        ) : value === "elecEngr" ? (
          <div style={{ marginBottom: 30 }}>
            <h1
              className={styles.aboutHeadings}
              style={{ color: secondaryColor }}
            >
              Electrical Services
            </h1>
            <p className={styles.aboutText} style={{ color: primaryColor }}>
              Energy audits, demand assessment, supply and system resilience,
              utility advice, and sustainable electrical engineering design are
              all part of our complete offerings.
            </p>
            <p className={styles.aboutText} style={{ color: primaryColor }}>
              We satisfy the creative and commercial demands of designers and
              developers in commercial property, retail, sport, mission-critical
              buildings, the arts, and any market.
            </p>
            <p className={styles.aboutText} style={{ color: primaryColor }}>
              SMK wants its electrical engineers to become innovative
              collaborators in the design process, in addition to conventional
              areas of overlap. Our collaborative approach is aided by
              innovative 3D modeling techniques known as building information
              modeling (BIM), which enable design teams to share an intelligent
              virtual project prototype.
            </p>
            <p className={styles.aboutText} style={{ color: primaryColor }}>
              We ensure that clients profit from innovation rather than risk it
              by doing in-house research and development. As new energy concepts
              develop, such as smart grids, SMK assists customers in determining
              where they are suitable and feasible in their operations.
            </p>
          </div>
        ) : value === "mechEngr" ? (
          <div style={{ marginBottom: 30 }}>
            <h1
              className={styles.aboutHeadings}
              style={{ color: secondaryColor }}
            >
              Mechanical Engineering
            </h1>
            <p className={styles.aboutText} style={{ color: primaryColor }}>
              Mechanical services like ventilation, heating, and cooling may go
              unseen when properly constructed - working with our customers, we
              design them to produce environments that are comfortable for
              occupants, economical for owners, and resource-efficient.
            </p>
            <p className={styles.aboutText} style={{ color: primaryColor }}>
              Mechanical engineers create simple and elegant solutions that
              increase a property's long-term worth. Our expertise includes
              passive and energy-efficient solutions that contribute to
              sustainable building design, as well as evaluation and retrofit
              for improved service performance in existing buildings.
            </p>
          </div>
        ) : value === "fireEngr" ? (
          <div style={{ marginBottom: 30 }}>
            <h1
              className={styles.aboutHeadings}
              style={{ color: secondaryColor }}
            >
              Fire Engineering
            </h1>
            <p className={styles.aboutText} style={{ color: primaryColor }}>
              Because of SMK's innovative approach to fire engineering, we are
              able to meet the problems given by various sorts of structures.
            </p>
            <p className={styles.aboutText} style={{ color: primaryColor }}>
              To stay at the forefront of fire engineering, we give insight into
              risk and human behavior, innovative simulation and analytical
              methodologies.
            </p>
            <p className={styles.aboutText} style={{ color: primaryColor }}>
              Our work is based on fire science, whether we are offering code
              consultation, strategic counsel, fire prevention through
              specialized fire safety design, construction supervision,
              inspection and risk assessment, or post-fire rehabilitation.
            </p>
          </div>
        ) : value === "soilTesting" ? (
          <div style={{ marginBottom: 30 }}>
            <h1
              className={styles.aboutHeadings}
              style={{ color: secondaryColor }}
            >
              Soil Testing
            </h1>
            <p className={styles.aboutText} style={{ color: primaryColor }}>
              The condition of the soil on your building site is critical to the
              success of your projects. As a result, to establish the soil's
              potential to sustain your construction, you must first define its
              properties. soil testing allows you to analyze the appropriateness
              of the soil, providing you with critical data for informed
              decision-making and planning.
            </p>
            <p className={styles.aboutText} style={{ color: primaryColor }}>
              Our soil testing services investigate the chemical and
              geotechnical qualities of your soil, allowing you to:
            </p>
            <p className={styles.aboutText} style={{ color: primaryColor }}>
              • Determine the appropriateness of the soil and if it can support
              your building project <br /> • Identify the various types of soil
              on your property and their locations. <br /> • Assess the
              influence of your soil's strength, density, compaction, pollution,
              organics, and sand content on your building project. <br /> •
              Collect the information required to generate technical and safety
              data reports to support planning approvals and licensing
              applications. <br /> • For best quality and safety, obtain exact
              findings and monitor soil development during your building
              project. <br /> • Complete laboratory testing for shear strength,
              compressive strength, consolidation, permeability, foundation
              load, soil index, and moisture/density <br /> • Software analysis{" "}
              <br /> • Technical help and advice
            </p>
          </div>
        ) : value === "Lift" ? (
          <div style={{ marginBottom: 30 }}>
            <h1
              className={styles.aboutHeadings}
              style={{ color: secondaryColor }}
            >
              Lift Installation Services
            </h1>
            <p className={styles.aboutText} style={{ color: primaryColor }}>
              With decades of expertise, our design and planning team is ready
              to assist customers throughout the whole elevator design and
              installation process, ensuring the finest possible elevator
              solution.
            </p>
            <p className={styles.aboutText} style={{ color: primaryColor }}>
              Every customer, every building, and every elevator installation is
              unique, which is why we tailor each proposal to our clients'
              needs. Our skilled specialists will chat with you and evaluate the
              project's precise parameters to precisely scope, develop, and plan
              for delivery.
            </p>
            <p className={styles.aboutText} style={{ color: primaryColor }}>
              We'll make certain that the transition from design and planning to
              actual product installation is as smooth as possible.
            </p>
            <p className={styles.aboutText} style={{ color: primaryColor }}>
              We install various sorts of elevators, including:
            </p>
            <p className={styles.aboutText} style={{ color: primaryColor }}>
              • Freight • Passenger • Hydraulic • Traction • Dumbwaiters <br />{" "}
              • Residential elevators • Wheelchair lifts • Restricted Use,
              Restricted Access (LULAs)
            </p>
          </div>
        ) : (
          <h1>No Service exists</h1>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Services;
