import { BrowserRouter, Switch, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import About from "./pages/About";
import CLientsPage from "./pages/Clients";
import Contact from "./pages/Contact";
import Home from "./pages/Home/home";
import Project from "./pages/Projects";
import Services from "./pages/Services";
import TeamMember from "./pages/TeamMember";
import MessengerCustomerChat from "react-messenger-customer-chat";
import ScrollToTop from "./constants/scrollTop";
function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <MessengerCustomerChat pageId="102979235375444" appId="799840747340869" />
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/services" component={Services} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/clients" component={CLientsPage} />
        <Route exact path="/projects" component={Project} />
        <Route exact path="/member/:name" component={TeamMember} />
        <Route exact path="/services/:textBlue" component={TeamMember} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
